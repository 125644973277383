import { useHead, useRuntimeConfig } from '#imports'
import { type Ref, computed } from 'vue'

import { productAPI } from '@backmarket/http-api'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { type Features } from '~/features'
import type { MetaTypeKeys } from '~/utils/seo/constants'

import { getMetas } from '../../../utils/seo/meta'
import {
  buildProductSchema,
  prepareJsonLdScript,
} from '../../../utils/seo/schema'

import translations from './useProductHead.translations'

export function useProductHead(
  product: Ref<productAPI.GetProductResponse | null>,
  offer: Ref<productAPI.GetBestOffersResponse[number] | null | undefined>,
  rating: Ref<Rating | null>,
  isOutOfStock: Ref<boolean>,
) {
  const i18n = useI18n()
  const { features } = useMarketplace<Features>()
  const runtimeConfig = useRuntimeConfig()

  const title = computed(() => {
    if (!product.value) {
      return ''
    }

    return `${i18n(translations.metaTitle, { title: product.value.rawTitle })} | ${runtimeConfig.public.COMPANY_NAME}`
  })

  const description = computed(() => {
    if (!product.value) {
      return ''
    }

    return i18n(translations.metaDescription, {
      title: product.value.title,
      warrantyDuration: features.warranty.duration,
      coolingOffDays: features.coolingOffDays,
      category: product.value.trackingDetails.category3Name,
      brand: product.value.trackingDetails.brand,
      model: product.value.model,
    })
  })

  /**
   * Alternates links are retrieved from the product endpoint, helping google to crawl the current product in alternative locales.
   * Canonical is here to help preventing duplicate content with URLs containing query params for instance.
   */
  const link = computed(() => {
    if (!product.value) {
      return []
    }

    return [
      ...Object.values(product.value.links).map(
        ({ href, params: { locale } }) => ({
          rel: 'alternate',
          hreflang: locale,
          href,
        }),
      ),
      {
        // In pastrami, we were using the router to generate this canonical, here i'm using the value sent by the API.
        href: product.value.link.href,
        rel: 'canonical',
      },
    ]
  })

  const meta = computed(() => {
    if (!product.value) {
      return []
    }

    // checking listing in the URL should be enough

    const metas: Record<MetaTypeKeys | string, string> = {
      title: title.value,
      description: description.value,
      image: product.value.images?.[0]?.url ?? null,
      'og:type': 'product',
    }

    return getMetas(metas)
  })

  const script = computed(() => {
    if (!product.value || !rating.value || !offer.value) {
      return []
    }

    return [
      prepareJsonLdScript(
        buildProductSchema({
          title: product.value.rawTitle,
          description: description.value,
          image: product.value.images?.[0]?.url ?? null,
          brand: product.value.trackingDetails.brand,
          rate: rating.value ?? undefined,
          price: offer.value.price,
          isOutOfStock: isOutOfStock.value,
          companyName: runtimeConfig.public.COMPANY_NAME,
        }),
      ),
    ]
  })

  useHead({
    title,
    link,
    meta,
    script,
  })
}
